<template>
  <a-modal v-model="show" title="编辑新闻信息" :width="800" @ok="doSubmit" @cancel="_close" centered destroyOnClose>
    <a-form-model ref="formRef" :model="formData" :rules="formRule">
      <a-row :gutter="15">
        <a-col span="16">
          <a-form-model-item label="标题" label-position="top" prop="title">
            <a-input v-model="formData.title" placeholder="请输入标题"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col span="8">
          <a-form-model-item label="类别" label-position="top" prop="categoryId">
            <a-select v-model="formData.categoryId" placeholder="请选择类别">
              <a-select-option :value="item.categoryId" v-for="item in category" :key="item.categoryId">{{
                item.name
              }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>

      </a-row>

      <a-form-model-item prop="content">
        <editor v-model="formData.content" />
      </a-form-model-item>
      <a-row :gutter="15">
        <a-col span="4">
          <a-form-model-item label="发布状态" label-position="top" prop="status">
            <a-select v-model="formData.status">
              <a-select-option value="未发布">未发布</a-select-option>
              <a-select-option value="已发布">已发布</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col span="4">
          <a-form-model-item label="是否置顶" label-position="top" prop="isTop">
            <a-switch v-model="formData.isTop"></a-switch>
          </a-form-model-item>
        </a-col>
        <a-col span="6">
          <a-form-model-item label="图片（660*360）" label-position="top" prop="thumb">
            <up-img ref="upimg" :thumb="formData.src"></up-img>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <a-spin size="large" fix v-if="loading" />
  </a-modal>
</template>

<script>
import request from "@/config/request";
import utils from "@/common/utils";
import apiUtil from "@/common/apiUtil";
import upImg from '@/components/common/upImg.vue';

export default {
  components: { upImg },
  data() {
    return {
      upimg: this.$refs.upimg,
      show: false,
      loading: false,
      formData: {
        newsId: '',
        categoryId: '',
        title: "",
        content: "",
        isTop: false,
        status: "已发布",
        src: '',
      },
      formRule: {
        categoryId: [{ required: true, message: '请选择分类' }],
        title: [{ required: true, message: '请填写标题' }],
        content: [{ required: true, message: '请填写内容' }],
      }
    };
  },
  methods: {
    ...apiUtil,
    _show(form) {
      this.show = true;
      this.$nextTick(() => {
        this.$refs.formRef.resetFields();
        if (form) {
          this.formData = JSON.parse(JSON.stringify(form));
        } else {
          this.formData.newsId = '';
          this.formData.content = '';
          this.formData.src = '';
        }
      });
    },
    _close() {
      this.$refs.formRef.resetFields();
      this.show = false;
      this.$emit('close');
    },
    doSubmit() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          utils.showSpin();
          this.formData.src = this.$refs.upimg.img;
          request.post('/platform/news/save', this.formData).then(ret => {
            this.$refs.formRef.resetFields();
            // console.log(ret)
            this.show = false;
            if (ret.code == 200) {
              utils.success('操作成功！').then(() => {
                this.show = false;
                this.$emit('success');
              });
            }
          });
        }
      });
    }
  },
  props: {
    category: {
      type: Array,
      default: () => {
        return [];
      }
    },
    organId: {
      type: Number | String,
      default: () => {
        return apiUtil.getOrganId();
      }
    }
  },
  watch: {
    organId(v) {
      this.formData.organId = v;
    }
  }
};
</script>

<style scoped>
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
</style>
